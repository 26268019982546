import { IWidgetDataSettings } from '../../_common/interfaces/widget-data-settings.interface';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import WidgetType = ToroEnums.WidgetType;

export class EzLocatorConfig implements IWidgetDataSettings {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.selectedGreenId != null) this.selectedGreenId = +json.selectedGreen;
        }
    }

    isEzLocatorConfig = true;

    widgetType: WidgetType;
    showStaleDataAlert: boolean;
    dataRefreshIntervalMinutes: number;
    staleDataThresholdMinutes: number;
    selectedCourseAccessId: string;
    selectedGreenId: number;
}
