import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

import * as Highcharts from 'highcharts';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../../../../api/dash-user/models/dash-user-preferences.model';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { MayaArea } from '../../../../../api/maya/models/maya-area.model';
import { MayaManagerService } from '../../../../../api/maya/maya-manager.service';
import { MayaSite } from '../../../../../api/maya/models/maya-site.model';
import { MayaSitesAndAreas } from '../../../../../api/maya/models/maya-sites-and-areas.model';
import { MayaSubstanceMetrics } from '../../../../../api/maya/models/maya-substance-metrics.model';
import { MayaSubstancesMetrics } from '../../../../../api/maya/models/maya-substances-metrics.model';
import { SelectItem } from 'primeng/api';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { TranslateService } from '@ngx-translate/core';

const More = require('highcharts/highcharts-more');
More(Highcharts);

@Component({
    selector: 'toro-widget-maya',
    templateUrl: './widget-maya.component.html',
    styleUrls: ['./widget-maya.component.less']
})
export class WidgetMayaComponent extends ToroDashboardWidget implements OnInit {
    @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;

    iconColor = 'black';
    title = 'WIDGET.MAYA';

    Highcharts = Highcharts;
    gaugeOptions: any = null;
    isGaugeLoaded = true;

    protected chartContainerWidth = 0;
    protected chartContainerHeight = 0;
    protected substanceN: MayaSubstanceMetrics;
    protected substanceP: MayaSubstanceMetrics;
    protected substanceK: MayaSubstanceMetrics;
    protected coursesSelectList: SelectItem[] = [];
    protected areasSelectList: SelectItem[] = [];
    protected isLoadingData = false;

    private mayaSites: MayaSitesAndAreas;
    private userPreferences: DashUserPreferences;

    private _selectedSite: MayaSite;
    protected set selectedSite(value: MayaSite) {
        if (!this.isBusy && value?.idsite != this._selectedSite?.idsite) {
            this.isLoadingData = true;
        }

        this._selectedSite = value;
        this.setAreasSelectList();

        this.userPreferences.mayaSelectedSiteId = value.idsite;
        this.dashUserManager.updateDashUserPreferences(this.userPreferences).subscribe()
    }

    protected get selectedSite(): MayaSite {
        return this._selectedSite;
    }

    protected _selectedArea: MayaArea;
    protected set selectedArea(value: MayaArea) {
        if (!this.isBusy && value?.idtag != this._selectedArea?.idtag) {
            this.isLoadingData = true;
        }

        this._selectedArea = value;
        this.setAreaData();

        this.userPreferences.mayaSelectedAreaId = value.idtag;
        this.dashUserManager.updateDashUserPreferences(this.userPreferences).subscribe()
    }

    protected get selectedArea(): MayaArea {
        return this._selectedArea;
    }

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private mayaManager: MayaManagerService,
                protected translateService: TranslateService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    get analyticsWidgetName(): string {
        return AnalyticsEvent.MayaWidgetName;
    }

    protected getWidgetData(isManualRefresh) {
        if (isManualRefresh) {
            this.isBusy = true;
        }

        const sources: Observable<any>[] = [
            this.dashUserManager.getDashUserInfo().pipe(take(1)),
            this.mayaManager.getMayaSitesAndAreas().pipe(take(1))
        ];

        forkJoin(sources)
            .pipe(
                finalize(() => {
                    this.isBusy = false;
                    this.isWidgetInitialized = true;
                })
            )
            .subscribe({
                next: ([userInfo, sites]) => {
                    this.userPreferences = userInfo?.preferences;
                    this.mayaSites = sites;

                    // Load previously selected Site and Area on initial widget load.
                    if (!this.isWidgetInitialized && this.userPreferences?.mayaSelectedSiteId != null) {
                        this._selectedSite = this.mayaSites?.data?.find(site => site.idsite == this.userPreferences.mayaSelectedSiteId);

                        if (this._selectedSite != null && this.userPreferences?.mayaSelectedAreaId != null) {
                            this._selectedArea = this._selectedSite.tags?.find(tag => tag.idtag == this.userPreferences.mayaSelectedAreaId);
                        }
                    }

                    this.setSitesSelectList();
                    this.lastUpdateTimestamp = new Date();
                },
                error: err => {
                    this.dashMessageService.showWidgetDataFetchErrorMessage('WIDGET.TORO_NSN');
                }
            })

    }

    private setSitesSelectList() {
        this.coursesSelectList = [];

        if (this.mayaSites?.data?.length > 0) {
            this.mayaSites.data.forEach((item) => {
                this.coursesSelectList.push({ label: item.name, value: item })
            })

            if (!this.selectedSite) {
                this.selectedSite = this.mayaSites.data[0];
            } else {
                this.selectedSite = this.coursesSelectList.find(site => site.value.idsite == this.selectedSite.idsite)?.value || this.mayaSites.data[0];
            }
        }
    }

    private setAreasSelectList() {
        this.areasSelectList = [];

        if (this.selectedSite?.tags != null) {
            const sortedAreas = this.selectedSite.tags.sort((a, b) => a.tag_name.localeCompare(b.tag_name));

            sortedAreas.forEach((area: MayaArea) => {
                this.areasSelectList.push({ label: area.tag_name, value: area })
            })

            if (!this.selectedArea) {
                this.selectedArea = this.areasSelectList[0]?.value;
            } else {
                this.selectedArea = this.areasSelectList.find(area => area.value.idtag == this.selectedArea.idtag)?.value;
            }
        }
    }

    private setAreaData() {
        if (!this.selectedArea || !this.selectedSite) {
            this.clearDataDisplay();
            return;
        }

        this.mayaManager.getMayaSubstancesMetrics((new Date()).getFullYear(), this.selectedSite.idsite, this.selectedArea.idtag)
            .pipe(
                take(1),
                finalize(() => {
                    this.isLoadingData = false;
                    this.isBusy = false;
                })
            )
            .subscribe({
                next: (metrics: MayaSubstancesMetrics) => {
                    this.substanceN = metrics.data.find(s => s.substance == 'N');
                    this.substanceP = metrics.data.find(s => s.substance == 'P');
                    this.substanceK = metrics.data.find(s => s.substance == 'K');

                    if (this.substanceN == null) {
                        this.substanceN = this.getEmptySubstance('N');
                    }

                    if (this.substanceP == null) {
                        this.substanceP = this.getEmptySubstance('P');
                    }

                    if (this.substanceK == null) {
                        this.substanceK = this.getEmptySubstance('K');
                    }

                },
                error: err => {
                    this.clearDataDisplay();
                }
            })
    }

    private clearDataDisplay() {
        this.substanceN = this.getEmptySubstance('N');
        this.substanceP = this.getEmptySubstance('P');
        this.substanceK = this.getEmptySubstance('K');
    }

    private getEmptySubstance(substance: string): MayaSubstanceMetrics {
        return new MayaSubstanceMetrics({
            substance: substance,
            spraying_value: 0,
            planned_value_current: 0,
            planned_value_total: 0
        })
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onNavigateToSite() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_MAYA' });
        setTimeout(() => window.open(environment.strikeGuardSiteUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }
}
