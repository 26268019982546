<div id="my-gauge-container">
    <div #chartContainer id="my-gauge-wrapper" class="prevent-select">
        <highcharts-chart
                *ngIf="gaugeOptions && substance != null"
                class="toro-highcharts-chart" [ngClass]="{'fade-in-chart': isGaugeLoaded}"
                [Highcharts]="Highcharts"
                [options]="gaugeOptions"
        ></highcharts-chart>
    </div>
</div>
