import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MayaSitesAndAreas } from './models/maya-sites-and-areas.model';
import { MayaSubstancesMetrics } from './models/maya-substances-metrics.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MayaApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient
    ) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getSitesAndAreas(): Observable<MayaSitesAndAreas> {
        return this.apiService.apiRequest<any>(MayaApiService.tenantDataUrl, HttpMethod.Get)
            .pipe(map((data: MayaSitesAndAreas) => new MayaSitesAndAreas(data)))
    }

    getSubstancesMetrics(year: number, siteId: string, tagId: string): Observable<MayaSubstancesMetrics> {
        return this.apiService.apiRequest<any>(MayaApiService.getSubstancesMetricsUrl(year, siteId, tagId), HttpMethod.Get)
            .pipe(map((data: MayaSubstancesMetrics) => new MayaSubstancesMetrics(data)))
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}maya`; }

    private static getSubstancesMetricsUrl(year: number, siteId: string, tagId: string) {
        return `${MayaApiService.baseUrl}/SubstancesMetrics/${year}/${siteId}/${tagId}`;
    }

    private static get tenantDataUrl() { return `${MayaApiService.baseUrl}/TenantData`; }

}
