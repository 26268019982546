/* eslint-disable @typescript-eslint/dot-notation */
import WidgetType = ToroEnums.WidgetType;

import { environment } from '../../../../environments/environment';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import { WidgetDataSettings } from '../../../core/dashboard/widgets/_models/widget-data-settings.model';

export class DashboardWidgetInfo {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.config) {
                this.config = JSON.parse(json.config);
            } else {
                // If we don't have a widget config, create an IWidgetDataSettings config for the widget.
                this.config = new WidgetDataSettings(this.widgetType);
            }

            // If the widget data settings have never been set, set them.
            if (this.config['showStaleDataAlert'] == null) {
                const tmpConfig = DashboardWidgetInfo.getDefaultConfig(this.widgetType);
                this.config.showStaleDataAlert = tmpConfig.showStaleDataAlert;
                this.config.dataRefreshIntervalMinutes = tmpConfig.dataRefreshIntervalMinutes;
                this.config.staleDataThresholdMinutes = tmpConfig.staleDataThresholdMinutes;
            }
        }
    }

    id: number;
    widgetType: ToroEnums.WidgetType;
    x: number;
    y: number;
    width = 1;
    height = 1;
    config: any;

    static getDefaultConfig(widgetType: WidgetType) {
        const config = new WidgetDataSettings(widgetType);
        config.showStaleDataAlert = true;
        config.dataRefreshIntervalMinutes = +environment.widgetDataUpdateIntervalInMinutes;
        config.staleDataThresholdMinutes = +environment.widgetDataStaleThresholdInMinutes;

        switch (widgetType) {
            case WidgetType.TaskTrackerLaborSummary:
            case WidgetType.TaskTrackerDailyMeasurements:
            case WidgetType.TaskTrackerSafety:
            case WidgetType.DecisionTree:
            case WidgetType.EzLocator:
            case WidgetType.Pogo:
            case WidgetType.SpectrumMoisture:
            case WidgetType.Maya:
                config.dataRefreshIntervalMinutes = 60;
                config.staleDataThresholdMinutes = 75;
                break;

            case WidgetType.PerryWeather:
            case WidgetType.Gcsaa:
            case WidgetType.GcsaaAlt:
            case WidgetType.WeatherAverages:
            case WidgetType.DtnWeather:
                config.dataRefreshIntervalMinutes = 15;
                config.staleDataThresholdMinutes = 60;
                break;

            case WidgetType.SoilScoutTemperature:
            case WidgetType.SoilScoutMoisture:
            case WidgetType.SoilScoutSalinity:
            case WidgetType.SoilScoutMobile:
            case WidgetType.SoilScoutMap:
                config.dataRefreshIntervalMinutes = 5;
                config.staleDataThresholdMinutes = 15;
                break;

            case WidgetType.StrikeGuard:
                config.dataRefreshIntervalMinutes = 5;
                config.staleDataThresholdMinutes = 60;
                break;
        }

        return config;
    }

}
